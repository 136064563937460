import { isEmpty, get as lodashGet, merge } from 'lodash';
import { submit, getFormValues, getFormAsyncErrors, stopAsyncValidation, change } from 'redux-form';
import { asyncForEach, newId, parseDataWithRequestFields } from 'utils';
import { getPageData } from 'utils/api';
import { translateHelperString as translateHelper } from 'utils/translate';
import { map } from 'utils/mappers';
import {
	backToBrowse,
	getCurrentHistory,
	getLocationParts,
	getParsedParams,
	getServiceNamespaceURL,
	goToURL
} from 'utils/location';
import {
	formatValues,
	normalizeAsyncFields,
	getAvailableFields,
	addRemoteFields,
	updateRemoteFields,
	defaultComponentsValuesMapper
} from 'utils/forms/forms';
import { getPathFiltersParameters, getQueryFiltersParameters, callRequest } from 'utils/request';
import { actions as editCreateActions } from 'modules/editCreate';
import { actions as asyncDependenciesActions } from 'modules/asyncDependencies';
import { asyncValidate, getValidationFields } from 'components/FormSection/validation';
import * as alertActions from 'modules/alerts/actions';
import * as types from './types';
import { getAdditionalFormSectionsValues } from './selectors';

const history = getCurrentHistory();

export const fetchDataRequest = () => formSection => ({
	meta: { name: formSection },
	type: types.FETCH_DATA_REQUEST
});

export const fetchDataSuccess = data => formSection => ({
	meta: { name: formSection },
	type: types.FETCH_DATA_SUCCESS,
	data
});

const fetchDataFailure = status => formSection => ({
	meta: { name: formSection },
	type: types.FETCH_DATA_FAILURE,
	status
});

export const saveDataRequest = () => formSection => ({
	meta: { name: formSection },
	type: types.SAVE_DATA_REQUEST
});

export const saveDataSuccess = () => formSection => ({
	meta: { name: formSection },
	type: types.SAVE_DATA_SUCCESS
});

export const saveDataFailure = () => formSection => ({
	meta: { name: formSection },
	type: types.SAVE_DATA_FAILURE
});

const setNavigationFlag = flag => formSection => ({
	meta: { name: formSection },
	type: types.SET_NAVIGATION_FLAG,
	flag
});

const changedField = (field, value) => formSection => ({
	meta: { name: formSection },
	field,
	value,
	type: types.SET_AFFECTED_FIELD
});

export const setFieldComponent = (field, component, id, visible = true) => formSection => ({
	meta: { name: formSection },
	type: types.SET_FIELD_COMPONENT,
	field,
	component,
	id,
	visible
});

/**
 * Function for get data for load or preload in FormSection
 * @param {object} preLoadData
 * @returns
 */
export const fetchData = preLoadData => formSection => async (dispatch, getState) => {
	const state = getState();
	const { formSections, editCreate } = state;
	const { schema, isFetching } = formSections[formSection];
	const { source, sourceField, currentViewData = {}, sourceEndpointParameters = [] } = schema;

	if (sourceField) {
		const data =
			sourceField === 'mainFormData'
				? editCreate.data
				: lodashGet(editCreate.data, sourceField) || {};
		return dispatch(fetchDataSuccess(data)(formSection));
	}

	if (preLoadData) {
		dispatch(fetchDataSuccess(preLoadData)(formSection));

		return dispatch(
			asyncDependenciesActions.executeGetAsyncDependencies(
				schema.dependencies,
				preLoadData,
				formSection,
				'formSection'
			)
		);
	}

	if (isFetching) return false;

	/* Add the current id to the provided source url or currentViewData id */
	const id = currentViewData.id || getLocationParts()[3];

	dispatch(fetchDataRequest()(formSection));

	let pathFilters = {};

	let filters = {};
	const formValues = getAdditionalFormSectionsValues(state);
	const dataValues = { id, ...formValues };

	if (sourceEndpointParameters.length) {
		pathFilters = getPathFiltersParameters(sourceEndpointParameters, dataValues) || {};
		filters = getQueryFiltersParameters(sourceEndpointParameters, dataValues, true) || {};
	}

	//	if sourceEndpointParameters has not defined id, use the current id
	const endpointParameters = pathFilters.id ? pathFilters : { ...pathFilters, id };

	try {
		const data = await getPageData({
			source,
			...filters,
			endpointParameters
		});

		dispatch(fetchDataSuccess(data)(formSection));

		dispatch(
			asyncDependenciesActions.executeGetAsyncDependencies(
				schema.dependencies,
				data,
				formSection,
				'formSection'
			)
		);
	} catch ({ response }) {
		if (response.status === 404) return dispatch(fetchDataSuccess({})(formSection));

		dispatch(fetchDataFailure({ status: response.status })(formSection));
	}
};

/**
 * Validate current form values
 * @param {string} formSection
 */
export const validateForm = formSection => async (dispatch, getState) => {
	const { subsection } = getParsedParams();
	const state = getState();

	const subsectionFormName = `${formSection}-${subsection}`;

	const hasSubsection = subsection && state.formSections[subsectionFormName];

	const validatedFormSectionName = hasSubsection ? subsectionFormName : formSection;

	const values = getFormValues(validatedFormSectionName)(state);

	const { schema } = state.formSections[validatedFormSectionName];

	const { fieldsGroup } = schema;

	const mainFields = fieldsGroup.reduce((accum, group) => {
		const normalizedFields = normalizeAsyncFields(group.fields);
		return [...accum, ...normalizedFields];
	}, []);

	const fields = getValidationFields(mainFields, values);

	let errorsAccumulated = {};

	await asyncForEach(fields, async (field, idx) => {
		if (field.validations?.length > 0) {
			const currentState = getState();

			const asyncErrors = getFormAsyncErrors(validatedFormSectionName)(currentState);
			const { registeredFields = {} } = lodashGet(currentState.form, validatedFormSectionName);
			try {
				await asyncValidate(
					values,
					dispatch,
					{
						t: translateHelper,
						asyncErrors,
						fieldsGroup,
						registeredFields,
						formSection: validatedFormSectionName
					},
					field.name,
					idx === 0,
					fields
				);
			} catch (errors) {
				errorsAccumulated = merge(errorsAccumulated, errors);
			}
		}
	});

	if (Object.keys(errorsAccumulated).length > 0) {
		dispatch(stopAsyncValidation(validatedFormSectionName, errorsAccumulated));
	}

	const lastState = getState();

	return {
		hasErrors: getFormAsyncErrors(validatedFormSectionName)(lastState),
		useSubSection: !!hasSubsection
	};
};

/**
 * Trigger edit form submission (the saveChanges action)
 * @param {string} flag - Flag that determinates the result redirection after successfully submitting the data.
 * If flag is "save", after a successful submission the user will be sent back to the Browse view. (Save button)
 * If flag is "save_new", the user will end up in the Create view. (Save and Create button)
 * If flag is "apply", the user will end on the same page. (Apply buttom)
 */
export const triggerSubmit = flag => formSection => async dispatch => {
	dispatch(setNavigationFlag(flag)(formSection));

	const { hasErrors, useSubSection } = await dispatch(validateForm(formSection));

	if (isEmpty(hasErrors || {})) {
		const { subsection } = getParsedParams();
		dispatch(submit(useSubSection ? `${formSection}-${subsection}` : formSection));
	}
};

/**
 * Send changes made on the Edit view. Trigger Toast alerts in case of errors and redirect on success.
 * @param {function} onSuccessCallback - function for execute on sucess request
 */
export const saveChanges = (onSuccessCallback, dataFormatter) => formSection => async (
	dispatch,
	getState
) => {
	dispatch(saveDataRequest()(formSection));

	const state = getState();

	const { form, formSections, page } = state;

	const {
		schema: { saveRedirectUrl }
	} = page;

	const { schema, navigationFlag, fieldsComponents } = formSections[formSection];

	const {
		target,
		fieldsGroup,
		currentViewData = {},
		targetEndpointParameters = [],
		requestFields
	} = schema;

	const id = currentViewData.id || getLocationParts()[3] || currentViewData.ids;

	let pathFilters = {};
	let filters = {};

	const { values: formValues, registeredFields = {} } = lodashGet(form, formSection);

	if (targetEndpointParameters.length) {
		const formattedValues = getAdditionalFormSectionsValues(state, formSection);

		if (!formattedValues.parentData)
			formattedValues.parentData = currentViewData.relatedData || currentViewData;

		pathFilters = getPathFiltersParameters(targetEndpointParameters, formattedValues) || {};
		filters = getQueryFiltersParameters(targetEndpointParameters, formattedValues) || {};
	}

	//	if sourceEndpointParameters has not defined id, use the current id
	const endpointParameters = pathFilters.id ? pathFilters : { ...pathFilters, id };

	try {
		const body = {
			...formatValues(
				formValues,
				getAvailableFields(fieldsGroup, fieldsComponents),
				registeredFields
			)
		};
		const formattedDataArray = dataFormatter(id, body, filters);

		const data = Array.isArray(id)
			? formattedDataArray
			: {
					...body,
					...filters
			  };

		const parsedData = requestFields ? parseDataWithRequestFields(data, requestFields) : data;

		const responseData = await callRequest(target, parsedData, endpointParameters, {
			Accept: 'application/json'
		});

		dispatch(saveDataSuccess()(formSection));

		// Call Toast for succes message
		dispatch(
			alertActions.addAlert({
				type: 'success',
				message: translateHelper('common.message.changesSaved')
			})
		);

		const saveAndCreate = path =>
			path.includes('new')
				? window.location.reload()
				: history.push(`${getServiceNamespaceURL()}/new`);

		const currentPathname = history.location.pathname;

		// Check action on save success

		if (navigationFlag === 'apply') {
			if (currentPathname.includes('new')) {
				return responseData.id
					? history.push(`${getServiceNamespaceURL()}/edit/${responseData.id}`)
					: backToBrowse();
			}
		}

		if (
			/apply/g.test(navigationFlag) &&
			!currentPathname.includes('/browse') &&
			navigationFlag !== 'applyFormClaim'
		)
			dispatch(editCreateActions.fetchData());

		if (navigationFlag === 'save')
			return saveRedirectUrl ? goToURL(saveRedirectUrl) : backToBrowse();

		if (navigationFlag === 'save_new') saveAndCreate(currentPathname);
		if (navigationFlag === 'applyFormClaim') {
			return onSuccessCallback({ ...body, type: currentViewData.type }, responseData);
		}

		if (onSuccessCallback && typeof onSuccessCallback === 'function')
			onSuccessCallback(responseData);
	} catch (error) {
		const { response = {} } = error;
		const { data, status } = response;

		dispatch(saveDataFailure()(formSection));

		const message = data?.message
			? translateHelper(data.message, data.messageVariables)
			: map('statusError', status);

		dispatch(alertActions.addAlert({ type: 'error', message }));
	}
};

export const addFieldId = ({ componentAttributes, ...field }) => ({
	...field,
	id: newId(),
	componentAttributes: {
		...componentAttributes,
		...(componentAttributes?.fields && {
			fields: componentAttributes.fields.map(addFieldId)
		})
	}
});

const formatSchema = schema => {
	const currentSchema = { ...schema };

	currentSchema.fieldsGroup = currentSchema.fieldsGroup.map(group => {
		const currentGroup = { ...group };
		currentGroup.fields = currentGroup.fields.map(addFieldId);
		return currentGroup;
	});

	return currentSchema;
};

export const createFormSection = (key, schema) => ({
	type: types.INIT_FORM_SECTION,
	formSection: key,
	schema: formatSchema(schema)
});

export const updateFormSection = (key, fields) => ({
	type: types.UPDATE_FORM_SECTION,
	formSection: key,
	fields
});

export const destroyFormSection = key => ({
	type: types.DESTROY_FORM_SECTION,
	formSection: key
});

/**
 *
 * @param {string} field - name Field affected by trigger
 */
export const setChangedField = (field, value) => formSection => dispatch =>
	dispatch(changedField(field, value)(formSection));

/**
 *
 * @param {string} flag - set navigation flag
 */
export const addNavigationFlag = flag => formSection => dispatch =>
	dispatch(setNavigationFlag(flag)(formSection));

/**
 * update form values to fields added for triggers
 * @param {Function} dispatch
 * @param {Array} fieldsGroupModified
 * @param {String} formSection
 * @param {String} parentField
 * @param {String} prefix
 */
const updatedCurrentData = ({
	dispatch,
	fieldsGroupModified,
	formSection,
	parentField,
	prefix
}) => {
	const updateDefaultValues = items => {
		items.map(({ key, component }) =>
			dispatch(change(formSection, key, defaultComponentsValuesMapper[component]))
		);
	};

	/**
	 *	get the fields added for the current fieldsArray trigger
	 * @param {String} currentPrefix
	 * @param {Array} fieldsGroup
	 * @param {String} parentName
	 * @returns
	 */
	const formattedFieldsWithFieldsArray = (currentPrefix, fieldsGroup, parentName) => {
		// find the current field index
		const [fieldName, indexField] = currentPrefix.split('_');

		return fieldsGroup.reduce((accum, group) => {
			const accumulator = [...accum];

			group.fields.forEach(innerField => {
				const {
					name,
					component,
					componentAttributes: { additionalFields = {} }
				} = innerField;

				if (
					component === 'FieldsArray' &&
					name === fieldName &&
					!!additionalFields[currentPrefix].length
				) {
					additionalFields[currentPrefix].forEach(currentField => {
						// filter the fields changed for the current parentField
						if (currentField.triggerParent === parentName)
							accumulator.push({
								...currentField,
								key: `${fieldName}.${indexField}.${currentField.name}`
							});
					});
				}
			});

			return accumulator;
		}, []);
	};

	/**
	 *	get the fields added for the current field trigger
	 * @param {Array} fieldsGroup
	 * @param {String} parentName
	 * @returns
	 */
	const formattedAdditionalFields = (fieldsGroup, parentName) =>
		fieldsGroup.reduce((accum, group) => {
			const accumulator = [...accum];

			group.fields.forEach(innerField => {
				const { triggerParent, name } = innerField;
				if (triggerParent === parentName) accumulator.push({ ...innerField, key: name });
			});

			return accumulator;
		}, []);

	const mappingFields = prefix
		? formattedFieldsWithFieldsArray(prefix, fieldsGroupModified, parentField)
		: formattedAdditionalFields(fieldsGroupModified, parentField);

	return mappingFields.length && updateDefaultValues(mappingFields);
};

/**
 * Action for add remote fields
 * @param {Array} fields
 * @param {Object} componentMapping
 * @param {String} parentField
 * @param {String} prefix
 * @param {Boolean} isUpdated
 */
export const setRemoteFields = (
	fields,
	componentMapping,
	parentField,
	prefix,
	isUpdated
) => formSection => (dispatch, getState) => {
	const {
		schema: { fieldsGroup }
	} = getState().formSections[formSection];

	let fieldsGroupModified;

	if (fields.length)
		fieldsGroupModified = addRemoteFields(
			fieldsGroup,
			fields,
			componentMapping,
			parentField,
			prefix
		);
	else fieldsGroupModified = updateRemoteFields(fieldsGroup, parentField, prefix);

	// remove remoteFields from formsValue
	if (isUpdated)
		updatedCurrentData({
			dispatch,
			fieldsGroupModified,
			formSection,
			parentField,
			prefix
		});

	return dispatch({
		type: types.SET_REMOTE_FIELDS,
		meta: { name: formSection },
		fieldsGroup: fieldsGroupModified
	});
};

/**
 * Action for remove or change remote fields
 * @param {String} parentField
 * @param {String} prefix
 */
export const removeRemoteFields = (parentField, prefix = '') => formSection => (
	dispatch,
	getState
) => {
	const { schema } = getState().formSections[formSection];

	const fieldsGroupModified = updateRemoteFields(schema.fieldsGroup, parentField, prefix);

	return dispatch({
		type: types.SET_REMOTE_FIELDS,
		meta: { name: formSection },
		fieldsGroup: fieldsGroupModified
	});
};
